<template>
  <div style="height: 100vh; display: flex; flex-direction: column">
    <nav v-if="showNav" id='nav' class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">欣博</a>
        <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
                data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a aria-current="page" class="nav-link" href="/vote">投票</a>
            </li>
            <!--            使用public中的图片-->
<!--            <img alt="My Image" src="../public/images/XinboLOGO.png">-->
            <!--        <li class="nav-item">-->
            <!--          <a class="nav-link active" href="#">Link</a>-->
            <!--        </li>-->
            <!--        <li class="nav-item dropdown">-->
            <!--          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">-->
            <!--            Dropdown-->
            <!--          </a>-->
            <!--          <ul class="dropdown-menu">-->
            <!--            <li><a class="dropdown-item" href="#">Action</a></li>-->
            <!--            <li><a class="dropdown-item" href="#">Another action</a></li>-->
            <!--            <li><hr class="dropdown-divider"></li>-->
            <!--            <li><a class="dropdown-item" href="#">Something else here</a></li>-->
            <!--          </ul>-->
            <!--        </li>-->
            <!--        <li class="nav-item">-->
            <!--          <a class="nav-link disabled">Disabled</a>-->
            <!--        </li>-->
          </ul>
          <!--      <form class="d-flex" role="search">-->
          <!--        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">-->
          <!--        <button class="btn btn-outline-success" type="submit">Search</button>-->
          <!--      </form>-->
        </div>
      </div>
    </nav>
    <main>
      <router-view/>
    </main>
  </div>
</template>

<style lang="scss">
main {
  flex-grow: 1;
}
</style>

<script>
export default {
  name: 'app',
  data () {
    return {
      showNav: true
    }
  }
}
</script>
